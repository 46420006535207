@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy'), url(../../fonts/Gilroy-Light.otf) format('opentype');
}

.header{
    height: 30%;
    background-color: #2E375B;
	font-family: 'Gilroy' !important;

    display:flex;
    justify-content:center;
    align-items:center;
}
.gilroy{
	font-family: 'Gilroy' !important;
}
.body{
    height: 70%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.inputField1{
    outline:none;
    padding:8px;
    box-sizing:border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
}
      
.inputField1:focus{
    border-color:rgb(67, 220, 148);
    box-shadow:0 0 0 1px rgb(67, 220, 148);
}

.inputField1::placeholder {
    color: #444444;
    opacity: 0.5;
  }

.inputWithIcon {
    position: relative;
}

.inputWithIcon img {
    position: absolute;
    left: 0;
    top: 0px;
    padding: 9px 8px;
    color: #aaa;
}

.invalidCredential{
    font-size: 0.75rem;
    color: #f65162;
}

.radio-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s, font-size 0.3s;
  }
  
  .radio-container:hover .radio-label {
    color: green;
    font-size: 16px; /* You can adjust the font size here */
  }
  
  .radio-label {
    margin-left: 5px;
  }
  
  
  
  
  