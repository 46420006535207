body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 15px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .feedback {
    padding: 0px 0;
  }

  .feedback form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.form-control:focus {
	border-color: #00bb11 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(108 234 13) !important;
}

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Nunito";
  }
  *{
	scrollbar-width: thin;
  }
  *::-webkit-scrollbar{
	width: 8px;
	background-color: transparent;
  }
  
  *::-webkit-scrollbar-thumb{
	background-color: #9e9c9c;
	border-radius: 5px;
  }
  body{
	width: inherit;
	max-width: inherit;
  }
  html{
	width: 100%;
	max-width: 100%;
  }
  .mainDiv{
	min-height: 100vh;
	background-color: #f5f5f5;
  }
  .label-class{
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */
	margin-bottom: 0.5rem;
	color: rgba(68, 68, 68, 0.5);
  }
  .App {
	  text-align: center;
  }
  
  .App-logo {
	  height: 40vmin;
	  pointer-events: none;
  }
  .buttonStandard2{
	font-size: 12px;
	padding: 0.1rem 1rem;
	color: #FF9777;
	background: transparent;
	border: 1px solid #FF9777;
	border-radius: 5px;}
  
  @media (prefers-reduced-motion: no-preference) {
	  .App-logo {
		  animation: App-logo-spin infinite 20s linear;
	  }
  }
  
  .App-header {
	  background-color: #282c34;
	  min-height: 100vh;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  font-size: calc(10px + 2vmin);
	  color: white;
  }
  
  .App-link {
	  color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	  from {
		  transform: rotate(0deg);
	  }
  
	  to {
		  transform: rotate(360deg);
	  }
  }
@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy'), url(/static/media/Gilroy-Light.c62aded7.otf) format('opentype');
}

.header{
    height: 30%;
    background-color: #2E375B;
	font-family: 'Gilroy' !important;

    display:flex;
    justify-content:center;
    align-items:center;
}
.gilroy{
	font-family: 'Gilroy' !important;
}
.body{
    height: 70%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.inputField1{
    outline:none;
    padding:8px;
    box-sizing:border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
}
      
.inputField1:focus{
    border-color:rgb(67, 220, 148);
    box-shadow:0 0 0 1px rgb(67, 220, 148);
}

.inputField1::placeholder {
    color: #444444;
    opacity: 0.5;
  }

.inputWithIcon {
    position: relative;
}

.inputWithIcon img {
    position: absolute;
    left: 0;
    top: 0px;
    padding: 9px 8px;
    color: #aaa;
}

.invalidCredential{
    font-size: 0.75rem;
    color: #f65162;
}
.loader {
    height: 50px;
    width: 50px;
    background: transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.info-body {
    width: 100%;
    min-height: 65px;
    height: inherit;
    margin: auto;
    border-radius: 10px;
    
  }
.info-body-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1.3rem 1rem;
} 
  .info-icon {
    align-self: flex-start;
  }
  
  .info-text {
    /* Nunito Regular 13 */
  
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    padding-left: 14px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
  
    /* black */
  
    color: #444444;
  }
  .info-button-parent{
    margin: 0.5rem 0;
  }
@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy'), url(/static/media/Gilroy-Light.c62aded7.otf) format('opentype');
}

.header{
    height: 30%;
    background-color: #2E375B;
	font-family: 'Gilroy' !important;

    display:flex;
    justify-content:center;
    align-items:center;
}
.gilroy{
	font-family: 'Gilroy' !important;
}
.body{
    height: 70%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.inputField1{
    outline:none;
    padding:8px;
    box-sizing:border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
}
      
.inputField1:focus{
    border-color:rgb(67, 220, 148);
    box-shadow:0 0 0 1px rgb(67, 220, 148);
}

.inputField1::placeholder {
    color: #444444;
    opacity: 0.5;
  }

.inputWithIcon {
    position: relative;
}

.inputWithIcon img {
    position: absolute;
    left: 0;
    top: 0px;
    padding: 9px 8px;
    color: #aaa;
}

.invalidCredential{
    font-size: 0.75rem;
    color: #f65162;
}

.radio-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s, font-size 0.3s;
  }
  
  .radio-container:hover .radio-label {
    color: green;
    font-size: 16px; /* You can adjust the font size here */
  }
  
  .radio-label {
    margin-left: 5px;
  }
  
  
  
  
  
